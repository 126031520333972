import React from 'react';
import HomeTop from '../TopHome';

const TermsAndConditions = () => {
  return (
    <div className="privacy-policy-container">
    <HomeTop />
  <div className="privacy-policy-content" style={{marginTop:15+"%"}}>
    <div>
      <h1>Terms and Conditions</h1>
      <p>Welcome to philoquent.ai! By accessing or using our Android app and services, you agree to be bound by the following terms and conditions:</p>

      <h2>Acceptance of Terms</h2>
      <p>These terms and conditions govern your use of our app and services. By downloading, installing, or using our app, you acknowledge that you have read, understood, and agreed to be bound by these terms and conditions.</p>

      <h2>Use of Services</h2>
      <p>Our app and services are provided for personal and non-commercial use only. You agree not to use our services for any illegal or unauthorized purposes.</p>

      <h2>Intellectual Property</h2>
      <p>All content, features, and functionality of our app and services, including but not limited to text, graphics, logos, images, and software, are the exclusive property of philoquent.ai and are protected by copyright, trademark, and other intellectual property laws.</p>

      <h2>Subscriptions and Payments</h2>
      <p>Our app and services are provided on a subscription basis. By subscribing to our services, you agree to pay the applicable fees and charges as described on our pricing page. We reserve the right to modify our pricing plans at any time.</p>

      <h2>Refund Policy</h2>
      <p>Please refer to our <a href="/refund-policy">Refund Policy</a> for information regarding refunds and cancellations.</p>

      <h2>Privacy</h2>
      <p>Our <a href="/privacy-policy">Privacy Policy</a> governs the collection, use, and disclosure of your personal information. By using our app and services, you consent to the practices outlined in our Privacy Policy.</p>

      <h2>Limitation of Liability</h2>
      <p>philoquent.ai shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use or inability to use our app and services.</p>

      <h2>Modifications</h2>
      <p>We reserve the right to modify or discontinue our app and services, or any part thereof, at any time without prior notice. We may also update these terms and conditions from time to time. Your continued use of our app and services after any changes constitutes acceptance of the updated terms and conditions.</p>

      <h2>Governing Law</h2>
      <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Your Country/State].</p>
    </div>
    </div>
    </div>
  );
};

export default TermsAndConditions;