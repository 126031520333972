import React from 'react';
import './App.css'; // Import your CSS file for styling

const Box = ({ icon, text }) => {
  return (
    <div className="box" style={{
        background: "#ffffff", // White background
        boxShadow: "0 0 20px 10px rgba(73, 186, 235, 0.4)" // Blue light shadow
      }}> <img src={icon} style={{width:80,height:80}}></img>
      <div className="text" style={{fontSize:15}}>{text}</div>
    </div>
  );
};

export default Box;
