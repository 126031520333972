import React, { useState } from 'react';
import './App.css';
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function ContactForm() {

    const firebaseConfig = {
        apiKey: "AIzaSyDU1ewh0vouYZdDvoU4f_xr-KB-Lwc_gL8",
        authDomain: "philomath-web.firebaseapp.com",
        projectId: "philomath-web",
        storageBucket: "philomath-web.appspot.com",
        messagingSenderId: "750741503708",
        appId: "1:750741503708:web:b27d2abe1a2beebb957e19",
        measurementId: "G-YP22P8VPHR"
      };
      const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
      const [snackbarSeverity, setSnackbarSeverity] = useState('success');

      const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

 const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!formData.name || !formData.email || !formData.message) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Please fill out all fields.');
      setSnackbarOpen(true);
      return;
    }

    try {
      // Add the form data to Firestore
      const docRef = await addDoc(collection(db, 'contacts'), {
        name: formData.name,
        email: formData.email,
        message: formData.message
      });
      console.log('Document written with ID: ', docRef.id);
      setSnackbarSeverity('success');
      setSnackbarMessage('Your message has been sent. Thank you!');
      setSnackbarOpen(true);
      // Clear form fields after successful submission
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred while sending your message. Please try again later.');
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="contact-form">
      <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: '2em',
  lineHeight: '71px',
  width:100+"%",
  textAlign:"center"
}}>
  Contact us
</div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows="4" required />
        </div>
        <button type="submit">Send</button>
      </form>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactForm;
