import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? "white"
            : "white",
        p: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Philoquent.ai private limited
			  We are a team of passionate engineers, who pickup challenging problems and try to solve them by deep tech products using Computer vision and NLP technologies.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
            Philoquent.ai private limited
            </Typography>
            <Typography variant="body2" color="text.secondary">
              JP Nagar 8th phase Bengaluru - 560083
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: rahul@philoquent.ai
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +919483322724
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://twitter.com/philoquentai" color="inherit">
              <Twitter />
            </Link>
            <Link
              href="https://www.linkedin.com/company/philoquentai/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <LinkedIn />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://philoquent.ai/">
             philoquent.ai
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}