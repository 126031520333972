import React, { useState } from 'react';
import { CardColumns, Col, Row } from 'react-bootstrap';
import useMobileDetect from 'use-mobile-detect-hook';
import './App.css';
import Box from './box';
import higherImage from './images/higher.jpg';
import upsc from './images/upsc.jpg';
import school from './images/school.jpg';
import robot from './images/robot.jpg';
import { Diversity1 } from '@mui/icons-material';
import { Bounce, Fade, Zoom } from 'react-reveal';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function HomeNew({scrollToRef,home,product,story,contactus}) {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const detectMobile = useMobileDetect();
  

  // Function to handle navbar position
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <div className="Landing">
   <div className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      { detectMobile.isMobile() ?  <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"auto",margin:0,flexDirection:"column"}}><img src={"https://i.postimg.cc/RVGVZBh6/philoquent-logo-without-text.png"} style={{width:60,height:60}}/><p style={{backgroundImage: " linear-gradient(to right, #4da1ff, #80b3ff)",color:"white",fontWeight:"bold",padding:5,borderRadius:10}}>Philoquent.ai</p></div>:null}
      <nav className={`navbar ${isNavOpen ? 'open' : ''}`} >
        <div className="nav-links" style={{justifyContent:"center",alignItems:"center"}} >
        <Link to="/" className="nav-button" onClick={()=>{}}>Home</Link>
          <Link to="/" className="nav-button" onClick={()=>{scrollToRef(product)}}>Product</Link>
           <Link to="/" className="nav-button" onClick={()=>{scrollToRef(contactus)}}>Contact Us</Link>
           <Link to="/terms-conditions" className="nav-button"  onClick={()=>{}}>Terms & Conditions</Link>
        </div>
        { detectMobile.isMobile() ? null : <div className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}><img src={"https://i.postimg.cc/RVGVZBh6/philoquent-logo-without-text.png"} style={{width:60,height:60}}/> <p style={{backgroundImage: " linear-gradient(to right, #4da1ff, #80b3ff)",color:"white",fontWeight:"bold",padding:5,borderRadius:10}}>Philoquent.ai</p>
      </div>}
        <div className="nav-links" style={{justifyContent:"center",alignItems:"center"}}>
      
        <Link to="/privacy-policy" className="nav-button" onClick={()=>{}}>Privacy-Policy</Link>
        <Link to="/refund-policy" className="nav-button"  onClick={()=>{}}>Refund-Policy</Link>
        </div>
      </nav>
      <div className="content" style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",height:"auto",marginTop:detectMobile.isMobile()?0:10+"%"}}>
        {!detectMobile.isMobile() && <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: '3.5em',
  lineHeight:'71px'
}}>
  <Fade>
  A Promise of enriching learning experience
  </Fade>
</div>}
{(!detectMobile.isMobile()) && <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: '3.5em',
  lineHeight: '71px'
}}>
  <Fade>
  filled completely with Fun and Joy through
  </Fade>
</div>}
{!detectMobile.isMobile() && <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: '3.5em',
  lineHeight: '71px'
}}>
  <Fade>
  convergence of innovation and deep intelligence
  </Fade>
</div>}

{detectMobile.isMobile() ? <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: detectMobile.isMobile ? '2em':'3.5em',
  padding:20
}}>
  A Promise of enriching learning experience filled completely with Fun and Joy through convergence of innovation and deep intelligence
</div>:null}
<p style={{backgroundImage: " linear-gradient(to right, #4da1ff, #49baeb)",color:"white",fontWeight:"bold",padding:10,borderRadius:10,marginTop:20,fontSize:detectMobile.isMobile()?15: 25}}>ONE BRAND MULTIPLE PRODUCTS</p>
<div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontStyle: 'normal',
  fontWeight: 700,
  marginTop:10,
  paddingLeft:20,
  paddingRight:20
}}>
  <Zoom>
  We are hand crafting not just mutiple products but an experience that will change the way you read and learn.  
  </Zoom>
</div>
<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row", width:100+"vw"}}>
<div style={{marginTop:20, width:100+"vw",display:"flex",flexDirection:detectMobile.isMobile()? "column":"row",justifyContent:"space-evenly",alignItems:"center"}}>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={robot} text="Super Assistant" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={higherImage} text="CAT/GRE/GMAT" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={upsc} text="UPSC/SSC/RBI" />
      </Bounce>
      </div>
      <div  style={{margin:20}}>
      <Bounce>
      <Box icon={school} text="NCERT/JEE" />
      </Bounce>
      </div>
    </div>

   
    </div>
       
       
      </div>
    </div>
  );
}

export default HomeNew;