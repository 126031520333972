import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

function TextAnimation() {
    const likelyCharacters = {
        'a': ['e', 'i', 'o', 'u', 'n'],
        'b': ['e', 'a', 'o', 'i', 'r'],
        'c': ['o', 'e', 'a', 'i', 'u'],
        'd': ['e', 'i', 'a', 'o', 'u'],
        'e': ['r', 's', 'a', 'i', 'n'],
        'f': ['o', 'e', 'a', 'i', 'r'],
        'g': ['e', 'i', 'a', 'o', 'r'],
        'h': ['e', 'a', 'o', 'i', 'u'],
        'i': ['n', 's', 'a', 'e', 't'],
        'j': ['u', 'o', 'a', 'e', 'i'],
        'k': ['e', 'i', 'a', 'o', 'u'],
        'l': ['e', 'i', 'a', 'o', 'u'],
        'm': ['e', 'o', 'a', 'i', 'u'],
        'n': ['d', 'e', 'i', 'a', 'o'],
        'o': ['n', 'r', 'u', 'l', 't'],
        'p': ['e', 'o', 'a', 'i', 'u'],
        'q': ['u', 'i', 'e', 'o', 'a'],
        'r': ['e', 'a', 'o', 'i', 'u'],
        's': ['e', 'i', 'a', 'o', 'u'],
        't': ['h', 'e', 'i', 'a', 'o'],
        'u': ['r', 'e', 'o', 'a', 'i'],
        'v': ['e', 'i', 'o', 'a', 'u'],
        'w': ['a', 'e', 'i', 'o', 'u'],
        'x': ['e', 'i', 'o', 'a', 'u'],
        'y': ['o', 'e', 'a', 'i', 'u'],
        'z': ['e', 'i', 'o', 'a', 'u']
      };

      
      const [sentence, setSentence] = useState('');
  const [generatedSentence, setGeneratedSentence] = useState('');
  const [currentCharIndex, setCurrentCharIndex] = useState(1);
  const [characterComplete,setCharacterComplete] = useState(false)
  const englishSentences = [
    "The sun sets in the west.",
    "Birds sing in the morning.",
    "Time flies when you're having fun.",
    "Laughter is the best medicine.",
    "Actions speak louder than words.",
    "A picture is worth a thousand words.",
    "Love conquers all.",
    "Practice makes perfect.",
    "What goes around comes around.",
    "Beauty is in the eye of the beholder.",
    "Where there's smoke, there's fire.",
    "Every cloud has a silver lining.",
    "Rome wasn't built in a day.",
    "The early bird catches the worm.",
    "Knowledge is power.",
    "When in Rome, do as the Romans do.",
    "Two heads are better than one.",
    "All that glitters is not gold.",
    "Necessity is the mother of invention.",
    "Don't count your chickens before they hatch.",
    "You can't judge a book by its cover.",
    "Curiosity killed the cat.",
    "The grass is always greener on the other side.",
    "Fortune favors the bold.",
    "Actions speak louder than words.",
    "All is fair in love and war.",
    "Don't cry over spilled milk.",
    "A penny for your thoughts.",
    "Patience is a virtue.",
    "The pen is mightier than the sword.",
    "A watched pot never boils.",
    "You can't make an omelet without breaking eggs.",
    "The best things in life are free.",
    "You catch more flies with honey than with vinegar.",
    "Better late than never.",
    "An apple a day keeps the doctor away.",
    "Money can't buy happiness.",
    "There's no smoke without fire.",
    "The more, the merrier.",
    "Two wrongs don't make a right.",
    "Where there is a will, there is a way.",
    "Don't put all your eggs in one basket.",
    "Out of the frying pan and into the fire.",
    "There's no time like the present.",
    "Beggars can't be choosers.",
    "It's a piece of cake.",
    "A friend in need is a friend indeed.",
    "The pot calling the kettle black.",
    "Easy come, easy go.",
    "The proof is in the pudding.",
    "What doesn't kill you makes you stronger.",
    "Let sleeping dogs lie.",
    "You can't have your cake and eat it too.",
    "A rolling stone gathers no moss.",
    "When the going gets tough, the tough get going.",
    "All talk and no action.",
    "The road to hell is paved with good intentions.",
    "You're a chip off the old block.",
    "A wolf in sheep's clothing.",
    "A watched pot never boils.",
    "You can't squeeze blood out of a turnip.",
    "The more things change, the more they stay the same.",
    "You can lead a horse to water, but you can't make him drink.",
    "You're cutting corners.",
    "The apple of my eye.",
    "You can't have too much of a good thing.",
    "A leopard can't change its spots.",
    "Don't throw the baby out with the bathwater.",
    "It's a piece of cake.",
    "You're barking up the wrong tree.",
    "Better safe than sorry.",
    "A picture is worth a thousand words.",
    "Actions speak louder than words.",
    "You can't make an omelet without breaking eggs.",
    "Don't count your chickens before they hatch.",
    "You can't judge a book by its cover.",
    "The early bird catches the worm.",
    "When in Rome, do as the Romans do.",
    "All that glitters is not gold.",
    "Every cloud has a silver lining.",
    "What goes around comes around.",
    "Knowledge is power.",
    "Two heads are better than one.",
    "All is fair in love and war.",
    "Don't cry over spilled milk.",
    "A penny for your thoughts.",
    "Patience is a virtue.",
    "The pen is mightier than the sword.",
    "A watched pot never boils.",
    "You can't make an omelet without breaking eggs.",
    "The best things in life are free.",
    "You catch more flies with honey than with vinegar.",
    "Better late than never.",
    "An apple a day keeps the doctor away.",
    "Money can't buy happiness.",
    "There's no smoke without fire.",
    "The more, the merrier.",
    "Two wrongs don't make a right.",
    "Where there is a will, there is a way.",
    "Don't put all your eggs in one basket."
  ];
  
  // Accessing individual sentences
  // ... and so on
  

  useEffect(()=>{
    const randomSentence = englishSentences[Math.floor(Math.random() * englishSentences.length)];
    setSentence(randomSentence)
    setGeneratedSentence(randomSentence)
  },[])

  function isAlphabet(char) {
    // Regular expression to match alphabets
    const alphabetRegex = /^[a-zA-Z]$/;
  
    // Test if the character matches the regex
    return alphabetRegex.test(char);
  }


  const generatedSentenceNow = async ()=>{
    if(currentCharIndex==sentence.length){
        return
    }
    if(!(isAlphabet(sentence[currentCharIndex]))||!(isAlphabet(sentence[currentCharIndex-1]))){
        setCharacterComplete((e)=>{return !e})
    }
    else{
    let newgen = generatedSentence
    newgen+='_'
    await setGeneratedSentence(newgen)
    const nextCharacters = likelyCharacters[sentence[currentCharIndex-1].toLowerCase()] || [];
    let i=1;
    nextCharacters.map((q)=>{
        setTimeout(()=>{
            // setGeneratedSentence((e)=>{e[currentCharIndex]=q; return e;})
            setGeneratedSentence((e)=>{return e.substring(0, currentCharIndex) + q + e.substring(currentCharIndex + 1);})
        },100+i*100)
        i=i+1
        if(i==4){
            setTimeout(()=>{
                // alert(sentence[currentCharIndex])
                // setGeneratedSentence((e)=>{e[currentCharIndex]=q; return e;})
                setCharacterComplete((e)=>{return !e})
            },100+(i+1)*100)
        }
    })
}

    // let generatedSentencenew = generatedSentence
    //         generatedSentencenew+=sentence[currentCharIndex];
    //         setGeneratedSentence(generatedSentencenew)
    // if(sentence[currentCharIndex]==' '){
    //     setGeneratedSentence((e)=>{return e+sentence[currentCharIndex]})
    // }
    // else{
       
    //     if(nextCharacters.length>0){
    //         nextCharacters.map((q)=>{
    //             setTimeout(()=>{
    //                 let generatedSentencenew = generatedSentence
    //                 // alert(currentCharIndex)
    //                 generatedSentencenew[currentCharIndex] = q
    //                 setGeneratedSentence(generatedSentencenew)
    //             },1000)
    //         })
    //     }
    //     else{
    //         setGeneratedSentence((e)=>{return e+sentence[currentCharIndex]})
    //     }
    // }
    // setCurrentCharIndex((e)=>{return e++})    
  }

  useEffect(()=>{
    if(sentence.length>0){
generatedSentenceNow()
    }
  },[currentCharIndex,sentence])

useEffect(()=>{
setGeneratedSentence(sentence.substring(0, currentCharIndex))
setCurrentCharIndex((e)=>{return e+1;})
},[characterComplete])

  return (
    <div style={{height:"auto", marginTop:20}}>
      <p style={{fontSize:0.8+"em"}}>
        <strong style={{fontSize:0.8+"em",color:"white"}}>REGENERATING USING BIGRAM MODEL TO GIVE A GLIMPSE OF THE WORKING MECHANISM OF LARGE LANGUAGE MODEL:</strong> {generatedSentence}
      </p>
    </div>
  )
}

export default TextAnimation