import React from 'react';
import './App.css';

function ArticleSection() {
  // Sample articles data
  const articles = [
    {
      title: "Republic News",
      description: "Founders of IIM Calcutta and XLRI Shape EdTech with Deep Intelligence ",
      link: "https://republicnewsindia.com/founders-of-iim-calcutta-and-xlri-shape-edtech-with-deep-intelligence/", // Replace with actual image URL
      imageUrl: "https://republicnewsindia.com/wp-content/uploads/2024/04/Founders-of-IIM-Calcutta-and-XLRI-Shape-EdTech-with-Deep-Intelligence-.jpeg", // Replace with actual article URL
    },
    {
      title: "Ani Media",
      description: "Philoquent.ai: The app that helped its founder score 99.9+ percentile in the VARC section of the CAT exam",
      link: "https://aninews.in/news/business/business/philoquentai-the-app-that-helped-its-founder-score-999-percentile-in-the-varc-section-of-the-cat-exam20230505125302/", // Replace with actual image URL
      imageUrl: "https://d3lzcn6mbbadaf.cloudfront.net/media/details/ANI-20230505072231.jpg", // Replace with actual article URL
    }
    // Add more articles as needed
  ];

  return (
    <div className="article-section">
      <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: '2em',
  lineHeight: '71px',
  width:100+"%",
  textAlign:"center"
}}>
  We Featured in
</div>
      <div className="articles-container">
        {articles.map((article, index) => (
          <div className="article" key={index}>
            <img src={article.imageUrl} alt={`Article ${index + 1}`} />
            <div className="article-info">
              <h3>{article.title}</h3>
              <p>{article.description}</p>
              <a href={article.link} target="_blank" rel="noopener noreferrer">Read more</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArticleSection;
