import React, { useState } from 'react';
import { CardColumns, Col, Row } from 'react-bootstrap';
import useMobileDetect from 'use-mobile-detect-hook';
import './App.css';
import Box from './box';
import higherImage from './images/higher.jpg';
import upsc from './images/upsc.jpg';
import school from './images/school.jpg';
import robot from './images/robot.jpg';
import { Diversity1 } from '@mui/icons-material';
import { Bounce, Fade, Zoom } from 'react-reveal';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function HomeTop() {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const detectMobile = useMobileDetect();

  // Function to handle navbar position
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsNavSticky(true);
    } else {
      setIsNavSticky(false);
    }
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Attach scroll event listener
  window.addEventListener('scroll', handleScroll);

  return (
    <div className="Landing">
   <div className={`menu-icon ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>
      { detectMobile.isMobile() ?  <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"auto",margin:0,flexDirection:"column"}}><img src={"https://i.postimg.cc/RVGVZBh6/philoquent-logo-without-text.png"} style={{width:60,height:60}}/><p style={{backgroundImage: " linear-gradient(to right, #4da1ff, #80b3ff)",color:"white",fontWeight:"bold",padding:5,borderRadius:10}}>Philoquent.ai</p></div>:null}
      <nav className={`navbar ${isNavOpen ? 'open' : ''}`} >
        <div className="nav-links" style={{marginLeft:10}} >
        <Link to="/" className="nav-button" onClick={()=>{}}>Home</Link>
          <Link to="/" className="nav-button" onClick={()=>{}}>Product</Link>
           <Link to="/" className="nav-button" onClick={()=>{}}>Contact Us</Link>
           <Link to="/terms-conditions" className="nav-button"  onClick={()=>{}}>Terms & Conditions</Link>
        </div>
        { detectMobile.isMobile() ? null : <div className="nav-logo" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}><img src={"https://i.postimg.cc/RVGVZBh6/philoquent-logo-without-text.png"} style={{width:60,height:60}}/> <p style={{backgroundImage: " linear-gradient(to right, #4da1ff, #80b3ff)",color:"white",fontWeight:"bold",padding:5,borderRadius:10}}>Philoquent.ai</p>
      </div>}
        <div className="nav-links" >
        <Link to="/privacy-policy" className="nav-button" onClick={()=>{}}>Privacy-Policy</Link>
        <Link to="/refund-policy" className="nav-button"  onClick={()=>{}}>Refund-Policy</Link>
        </div>
      </nav>
    </div>
  );
}

export default HomeTop;