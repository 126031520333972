import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './feature1.css'
import splash from './../images/notifications.gif'
function Feature3(){


   

        return(
			<div  style={{margin:3+"em", width:95+"%",backgroundColor:"#fff",flexDirection:"column",borderRadius:10}} className="container" >
		
	
			<div   style={{backgroundColor:"white", position:"relative",width:270+"px", height:520+"px",border:16+"px black solid",margin:"auto",marginTop:10, marginBottom:0, borderTopWidth:60+"px",borderBottomWidth:60+"px",borderRadius:36+"px"}}>

			<img src={splash} style={{width:240+"px", height:430+"px"}}></img>
             </div>
			<div  className="first-feature-text">
				<h1>Regular Reminders of Words</h1>
				<p style={{fontSize:22, fontFamily:'sans-serif'}}>A personal assistant to revise</p>
				<br></br>Select the word from any app in your smartphone and click on philomath in the options, It is that easy to search and bookmark the word without having to open the app explicitly.
			</div>
    </div>
   
        )


    


}
export default Feature3;