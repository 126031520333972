import React from 'react';
import Logo from './../images/philomath_logo.png'
import '../App.css'
import HomeTop from '../TopHome';

const RefundPolicy = () => {
  return (
    <div className="privacy-policy-container">
        <HomeTop />
      <div className="privacy-policy-content" style={{marginTop:15+"%"}}>

    <div>
      <h1>Refund Policy</h1>
      <p>
        At philoquent.ai, we offer a range of pricing plans for our Android app and services. We are committed to providing high-quality services and ensuring your satisfaction. If you are not entirely satisfied with our app or services, we offer a 100% cash-back guarantee within the first 3 days of your subscription.
      </p>
      <p>
        To request a refund, please contact our support team at <a href="mailto:rahul@philoquent.ai">rahul@philoquent.ai</a> within 3 days of your purchase. Our friendly support staff will assist you with the refund process and address any concerns you may have.
      </p>
      <p>
        Please note that refunds will be processed back to the original payment method, and it may take up to 5-7 business days for the refund to reflect in your account, depending on your financial institution.
      </p>
      <p>
        We strive to ensure a seamless and satisfactory experience for our users. If you have any further questions or need assistance, please do not hesitate to reach out to our support team.
      </p>

      <h2>Secure Transactions</h2>
      <p>
        At philoquent.ai, we prioritize the security and privacy of your financial information. We use industry-standard encryption and secure payment gateways to process all transactions. We do not store your credit card details or sensitive payment information on our servers. Instead, we rely on reputable third-party payment processors who handle your payment data securely and in compliance with relevant regulations.
      </p>
      <p>
        Our payment process is designed to minimize the risk of unauthorized access or misuse of your financial information. By following best practices and collaborating with trusted payment providers, we strive to ensure that your transactions with us are safe and confidential.
      </p>
    </div>
    </div>
    </div>
  );
};

export default RefundPolicy;
