import React, { useState } from 'react';
import './App.css'; // Import CSS file for styling

const Test = ({ data }) => {
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (questionIndex, option) => {
    setAnswers({ ...answers, [questionIndex]: option });
  };

  const wordCount = data ? data.passage.split(/\s+/).filter(word => word !== '').length : 0;

  
  const handleSubmit = () => {
    // Evaluate answers here
    console.log("Submitted answers:", answers);
    setSubmitted(true);
  };

  return (
    <div className="test-container">
    <h1>Test</h1>
    {data ? (
      <>
        <p className="word-count">Number of words in passage: {wordCount}</p>
        <p className="passage"><strong>Passage:</strong> {data.passage}</p>
        <ol>
          {data.questions.map((question, index) => (
            <li key={index}>
              <p>{question.question}</p>
              <ul className="options">
                {question.options.map((option, optionIndex) => (
                  <li key={optionIndex}>
                    <label className={submitted && question.answer_key === option.charAt(0) ? 'correct-answer' : ''}>
                      <input
                        type="radio"
                        name={`question-${index}`}
                        value={option.charAt(0)}
                        checked={answers[index] === option.charAt(0)}
                        onChange={() => handleChange(index, option.charAt(0))}
                        disabled={submitted}
                      />
                      {option}
                    </label>
                  </li>
                ))}
              </ul>
              {submitted && (
                <div className="feedback">
                  <p className="explanation">Explanation: <span className="explanation-text">{question.explanation}</span></p>
                  <p className="tip">Tip: <span className="tip-text">{question.tip}</span></p>
                  <p className="category">Category: {question.category}</p>
                </div>
              )}
            </li>
          ))}
        </ol>
        {!submitted && <button className="submit-button" onClick={handleSubmit}>Submit</button>}
      </>
    ) : (
      <p>No data provided.</p>
    )}
  </div>
  );
};

const TestWithJsonInput = () => {
  const [jsonData, setJsonData] = useState('');
  const [parsedData, setParsedData] = useState(null);

  const handleInputChange = (event) => {
    setJsonData(event.target.value);
  };

  const handleJsonSubmit = () => {
    try {
      const parsedJson = JSON.parse(jsonData);
      setParsedData(parsedJson);
    } catch (error) {
      console.error('Invalid JSON format:', error);
    }
  };

  return (
    <div className="input-container">
      <h1>Test With JSON Input</h1>
      <textarea
        rows="10"
        cols="50"
        value={jsonData}
        onChange={handleInputChange}
        placeholder="Enter JSON data here..."
        className="json-input"
      ></textarea>
      <br />
      <button className="json-submit" onClick={handleJsonSubmit}>Submit JSON</button>
      <br />
      {parsedData && <Test data={parsedData} />}
    </div>
  );
};

export default TestWithJsonInput;
