import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Footer from './Comp/footer';
import Header1 from './Comp/Header1';
import Wordsstories from './Comp/Wordsstories';
import "bootstrap/dist/css/bootstrap.css"
import "./index.css"
import pic from './images/pic.png'
import pc from './images/pc.png'
import logo from './images/philomath_logo.png'
import chrome from './images/chrom.png'
import Main from './Comp/main';
import Mediaplayer from './Comp/Videoplayer';
import Feature1 from './Comp/feature1';
import Feature2 from './Comp/feature2';
import Feature3 from './Comp/feature3';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Cards from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'material-icons/iconfont/material-icons.css';
import splash4 from './images/ai.gif'
import correct from './images/correct.gif'
import splash from './images/androidintegration.gif'
import { v4 as uuidv4 } from "uuid";
import splash2 from './images/quiz.gif'
import Card from "./Card";
import { Status } from "status-modal/dist";
import Carousel from "./carousel.jsx";
import { Dots } from 'loading-animations-react';
import splash3 from './images/notifications.gif'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { Wave } from 'react-animated-text';
import { TypeAnimation } from 'react-type-animation';
import { LightSpeed } from 'react-reveal';
import Carroussel from './carousel.jsx';
import TextAnimation from './textAnimation';
import HomeNew from './Home';
import ArticleSection from './articles';
import ContactForm from './contactform';
import Test from './secret';
import PrivacyPolicy from './Comp/privacy-policy';
import RefundPolicy from './Comp/refund-policy';
import TermsAndConditions from './Comp/terms-conditions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDU1ewh0vouYZdDvoU4f_xr-KB-Lwc_gL8",
  authDomain: "philomath-web.firebaseapp.com",
  projectId: "philomath-web",
  storageBucket: "philomath-web.appspot.com",
  messagingSenderId: "750741503708",
  appId: "1:750741503708:web:b27d2abe1a2beebb957e19",
  measurementId: "G-YP22P8VPHR"
};
// Initialize Firebase


function Root(){
  const app = initializeApp(firebaseConfig);
  const containerRef = useRef(null);
// Export firestore database
// It will be imported into your react app whenever it is needed
 const db = getFirestore(app);

 const home = useRef(null);
 const product = useRef(null);
 const story = useRef(null);
 const contactus = useRef(null);

 const scrollToRef = (ref) => {
   if (ref.current) {
     ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
   }
 };
 const analytics = getAnalytics(app);
  const [loading,setLoading] = useState(true)
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showAnimations,setShowAnimations]=useState(false)
  const [showText,setShowText]= useState(false)
  const [success,setSuccess] = useState(false)
  const [EmailWrong,setEmailWrong] = useState(false)
  const [first,setFirst]=useState(false)
  const [second,setSecond]=useState(false)
  const [third,setThird]=useState(false)
  const [fourth,setFourth]=useState(false)
  const [fifth,setFifth]=useState(false)
  const [sixth,setSixth]=useState(false)
  const [seventh,setSeventh]=useState(false)
  const [firstTime,setFirstTime] = useState(true)
  const [showAnimation,setShowAnimation] = useState(false)
  const [animationCompleted,setAnimationCompleted] = useState(true)

  
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(()=>{
    if(first){
      setTimeout(()=>{
        setSecond(true)
      },[600])
    }
    if(second){
      setTimeout(()=>{
        setThird(true)
      },[600])
    }
    
    if(third){
      setTimeout(()=>{
        setFourth(true)
      },[600])
    }
    
    if(fourth){
      setTimeout(()=>{
        setFifth(true)
      },[600])
    }
    
    if(fifth){
      setTimeout(()=>{
        setSixth(true)
      },[600])
    }
    
    if(sixth){
      setTimeout(()=>{
        setSeventh(true)
        setAnimationCompleted(true)
        setShowAnimation(false)
      },[600])
    }

    
  },[first,second,third,fourth,fifth,sixth])


  const [y, setY] = useState(window.scrollY);

const handleNavigation = useCallback(
  e => {
  
    const window = e.currentTarget;
    if (y > window.scrollY ) {
      if(seventh && !showAnimation){
        setAnimationCompleted(true)
      }
      console.log(showAnimation,animationCompleted)
    if(showAnimation || !animationCompleted){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      }
    } else if (y < window.scrollY) {
      
      if(width<600){
        console.log(y)
        if(y>1000){
          if(firstTime){
          const focused = window.document.querySelector('.interactive')
          focused.scrollIntoView({ behavior: 'auto' })
          containerRef.current.focus();
          setShowAnimation(true)
          setAnimationCompleted(false)
          setFirstTime(false)
        }
        }
      }
      else{
        if(firstTime){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      containerRef.current.focus();
      setShowAnimation(true)
      setFirstTime(false)
      }
    }
    if(firstTime){
    if(showAnimation || !animationCompleted){
      const focused = window.document.querySelector('.interactive')
      focused.scrollIntoView({ behavior: 'auto' })
      }
    }
  }
    setY(window.scrollY);
  }, [y]
);

useEffect(() => {

  // setY(window.scrollY);
  // window.addEventListener("scroll", handleNavigation);

  // return () => {
  //   window.removeEventListener("scroll", handleNavigation);
  // };
}, [handleNavigation]);

  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const [email,setEmail] = useState('')
  const addEmail = async (e) => {
        e.preventDefault();  
        if(validateEmail(email.trim())){
        try {
            const docRef = await addDoc(collection(db, "emails"), {
              email: email.trim(),    
            });
            console.log("Document written with ID: ", docRef.id);
            setEmail('')
            setSuccess(true)
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        }else{
          setEmailWrong(true)
        }
    }
 

const [load1,setload1] = useState(true)
const [load2,setload2] = useState(true)
const [load3,setload3] = useState(true)
const [load4,setload4] = useState(true)
const [load5,setload5] = useState(false)

const ref1 = useRef()
const ref2 = useRef()
const ref3 = useRef()


const onLoad1 = ()=>{
  setload1(true)
}
const onLoad2 = ()=>{
  setload2(true)
}
const onLoad3 = ()=>{
  setload3(true)
}
useEffect(() => {
  if(load1 && load2 && load3){
    setShowAnimations(true)
    setTimeout(()=>{
      setShowText(true)
    },500)
  }
}, [load1,load2,load3]);

useEffect(()=>{
console.log(width)
},[width])


  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen={pic} title={'Android app'} content={'Select the word from any app in your smartphone and click on philomath in the options, It is that easy to search and bookmark the word without having to open the app explicitly.'} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={pc} title={'Web app'} content={'Using Machine learning technology, we will suggest you best version of sentences or paragraphes you wrote through our powerful AI models, which can detect spelling mistakes, grammatical mistakes and even acronyms. All this from anywhere from your phone. '} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={chrome} title={'Chrome app'} content={'Machine learning model that is built by regularly crawling through famous english magazines and old exam question papers will give you probability of importance for the word and related word graphs'} />
      )
    }
  ];
return(
<>
<Router>
      <div>
        <Switch>
          {/* Route for the secret path */}
          <Route path="/super-secret">
            {/* Render the Test component when the URL is /secret */}
            <Test />
          </Route>
            <Route path="/terms-conditions">
            {/* Render the Test component when the URL is /secret */}
            <TermsAndConditions />
          </Route>
          <Route path="/privacy-policy">
            {/* Render the Test component when the URL is /secret */}
            <PrivacyPolicy />
          </Route>
          <Route path="/refund-policy">
            {/* Render the Test component when the URL is /secret */}
            <RefundPolicy />
          </Route>
          <Route path="/">
{load1 && load2 && load3 && load4 ? <div ref={home} style={{backgroundColor:"black",overflowAnchor:"none",display:'flex',flexDirection:"column",background:"white"}}>


    {/* <a href="/">Home</a> | <a href="/register">Add Bill</a> */}

<HomeNew scrollToRef={scrollToRef} home={home} product={product} story={story} contactus={contactus} />
    {/* <Footer></Footer> */}

<div className='main' id='main' style={{display:"flex",height:"auto",justifyContent:"space-evenly",alignItems:"center",width:100+"%",background:"white",padding:0}}>
 <div style={{backgroundColor:"white",height:100+"%", display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",padding:0,width:100+"%"}}>
  
 <div style={{
  fontFamily: 'Axiforma, system-ui, -apple-system, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif',
  fontWeight: 900,
  color: 'rgb(36, 34, 62)',
  fontSize: '2em',
  lineHeight: '71px',
  marginTop:width>600?50:5+"%"
}}>
  Product Features
</div>

<div ref={product} style={{display:"flex",flexDirection:width>600?"row":"column",justifyContent:"space-between",alignItems:"flex-start",width:width>600?90+"%":100+"%",marginBottom:5+"%",marginTop:width>600?5:0+"%"}}>
<Bounce>
<Cards  variant="outlined" style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", margin: 20, height: width > 600 ? 600 : 600 , background: "linear-gradient(180deg, #ffffff 0%, #c9e6ff 100%)"}}>
        <CardContent style={{ borderRadius: 10, display: "flex", justifyContent: "center", alignItems: "center", }}>
          <div style={{ width: width > 600 ? 300 : 80 + "%", padding: 10, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <img src='https://i.postimg.cc/JhJVw4x5/computer.png' style={{ width: width > 600 ? 250 : 80 + "%", height: width > 600 ? 250 : "auto" }} alt="computer" />
            <div style={{ textAlign: "center", color: "black", fontSize: 25, fontWeight: "bold", fontFamily: "Bolden" }}>Amalgamation of Deep Intelligence and Data</div>
            <div style={{ fontSize: 15, color: "black", textAlign: "center", marginTop: 4 + "%" }}>We seamlessly blend deep intelligence with vast datasets. We go beyond merely harnessing GenAI. our forte lies in crafting algorithms that not only foster enjoyable learning experiences but also cultivate a profound connection with learning through cutting-edge technology and sophisticated data analysis.</div>
          </div>
        </CardContent>
      </Cards>
      </Bounce>
      <Bounce>
   <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?600:600,background: "linear-gradient(180deg, #ffffff 0%, #c9e6ff 100%)"}}>
      <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
   <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
    <img src='https://i.postimg.cc/V66P5QcJ/Pngtree-online-education-training-course-design-5331074.png' style={{width:width>600?250:80+"%",height:width>600?250:"auto"}}/>
    <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>Unlocking potential of Mobile learning</div>
    <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>We are not just crafting a cutting-edge digital learning experience but we are revolutionizing it with a mobile-first approach. While our web platform offers comprehensive tools, we recognize that mobile devices have become students' closest companions. That is why we are harnessing our technology and advanced algorithms to make mobile a powerful learning device. </div>
   </div>
   </CardContent>
   </Cards>
   </Bounce>
   <Bounce>
   <Cards variant="outlined" style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center",margin:20,height:width>600?600:600,background: "linear-gradient(180deg, #ffffff 0%, #c9e6ff 100%)"}}>
      <CardContent style={{borderRadius:10,display:"flex",justifyContent:"center",alignItems:"center"}}>
   <div style={{width:width>600?300:80+"%",padding:10,display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
    <img src='https://i.postimg.cc/pTQz9zWc/pngwing-com-12.png' style={{width:width>600?220:65+"%",height:width>600?220:"auto",marginBottom:width>600?25:5}}/>
    <div style={{textAlign:"center",color:"black",fontSize:25,fontWeight:"bold",fontFamily:"Bolden"}}>Revolutionary Ecosystem</div>
    <div style={{fontSize:15,color:"black",textAlign:"center",marginTop:4+"%"}}>We are not just creating an innovative product but we are building a holistic ecosystem that engages students, parents, and teachers alike. We understand that the true essence of learning and its outcomes are amplified through the Teacher-Learner-Guardian model. That is why our ecosystem is designed to empower those who play pivotal roles in guiding and nurturing students, ultimately unlocking their full potential and helping them realize their dreams. </div>
   </div>
   </CardContent>
   </Cards></Bounce>

</div>
<div ref={story} style={{marginBottom:5+"%"}}>
<ArticleSection />
</div>
<div ref={contactus} style={{marginBottom:5+"%",width:100+"vw"}}>
<ContactForm />
</div>
<div style={{width:100+"vw"}}>
<Footer></Footer>
</div>
</div>
 </div>
 
</div>
:
<div style={{background:"linear-gradient(to bottom, #001F3F, #1E90FF)",display:"flex",width:100+"%",height:100+"vh",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
<div class="loader"></div>
<div style={{color:"white",fontSize:25,fontWeight:"bold",margin:20}}>Loading please wait...</div>
</div>}
</Route>
</Switch>
</div>
</Router>
</>
);

}
export default Root;